import React from "react"
import { classNames } from "../../utils/classNames"

type Props = {
  id?: string
  className?: string
  bg?: "gray" | "white" | "black"
  fullBleed?: boolean
  layout?: {}
  children: React.ReactNode
}

export const Placement: React.FC<Props> = ({
  id,
  children,
  className,
  bg = "white",
  layout,
  fullBleed,
}) => {
  return (
    <div
      id={id}
      className={classNames(
        "",
        bg === "gray" && "bg-gray-50",
        bg === "white" && "bg-white",
        bg === "black" && "bg-black",
        className
      )}
    >
      <div
        className={classNames(
          "mx-auto",
          fullBleed ? "max-w-screen-2xl" : "container"
        )}
      >
        {children}
      </div>
    </div>
  )
}
