import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./Layout.css"

import PageHeader from "../PageHeader"
import PageFooter from "../PageFooter"

type Props = {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PageHeader siteTitle={data.site.siteMetadata.title} />
      <main className="">{children}</main>
      <PageFooter />
    </>
  )
}

export default Layout
