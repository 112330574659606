import React, { Fragment, useEffect, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon, PhoneIcon, MailIcon } from "@heroicons/react/outline"
import { classNames } from "../utils/classNames"

import { LogoLink } from "./primitives"

import { navigation, contactEmail, contactPhoneNumber } from "../data"

function PageHeader() {
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 72) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <Popover as={Fragment}>
      <div
        className={classNames(
          "absolute w-full z-20",
        )}
      >
        <nav
          className="flex container items-center justify-between mx-auto px-4 py-4"
          aria-label="Global"
        >
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <LogoLink className="text-white h-12" />
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-transparent rounded-md p-2 inline-flex items-center justify-center border border-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-1 md:ml-10 md:pr-4 md:space-x-8 justify-end">
            {navigation.map(item => (
              <a
                key={item.name}
                href={item.href}
                className="font-medium text-gray-300 hover:text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          {/* <div className="hidden lg:flex justify-end w-full">
            <div className="flex flex-row space-x-8">
              <div>
                <div
                  className={classNames(
                    "transition transition-color duration-150 opacity-60 text-sm",
                    navbar ? "text-black" : "text-white"
                  )}
                >
                  Give us a call:
                </div>
                <div
                  className={classNames(
                    "flex items-center",
                    navbar ? "text-blue-500" : "text-white"
                  )}
                >
                  <PhoneIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  <a
                    className="text-current"
                    href={`tel:${contactPhoneNumber}`}
                  >
                    {contactPhoneNumber}
                  </a>
                </div>
              </div>
              <div>
                <div
                  className={classNames(
                    "transition transition-color duration-150 opacity-60 text-sm",
                    navbar ? "text-black" : "text-white"
                  )}
                >
                  Send us a message:
                </div>
                <div
                  className={classNames(
                    "flex items-center",
                    navbar ? "text-blue-500" : "text-white"
                  )}
                >
                  <MailIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                  <a className="text-current" href={`mailto:${contactEmail}`}>
                    {contactEmail}
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <LogoLink className="text-black h-12" />
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Close main menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
            {/* <div className="flex justify-around w-full px-5 py-3 text-center font-medium text-black bg-gray-50">
              <div className="flex items-center text-blue-500">
                <PhoneIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                <a href={`tel:${contactPhoneNumber}`}>{contactPhoneNumber}</a>
              </div>
              <div className="flex items-center text-blue-500">
                <MailIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </div>
            </div> */}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

PageHeader.defaultProps = {
  siteTitle: ``,
}

export default PageHeader
