import { MailIcon, PhoneIcon } from "@heroicons/react/outline"
import React from "react"
import { LogoLink } from "./primitives"

import { navigation, contactEmail, contactPhoneNumber } from "../data"

const description = `Fully remote throughout the U.S.`
const description2 = `Empower operational effectiveness and efficiency, emphasizing integrity and holistic product excellence`

export default function PageFooter() {
  return (
    <footer className="text-gray-600 body-font bg-black">
      <div
        className="flex flex-col flex-wrap container mx-auto px-4 py-4 md:items-center md:flex-row md:flex-nowrap lg:px-0"
        style={{ display: "none" }}
      >
        <div className="flex-shrink-0 md:mx-0 mb-2 md:mb-0">
          <LogoLink className="text-white h-10" />
        </div>
        <div className="flex flex-grow flex-wrap pl-8 md:text-left text-center">
          <div className="flex-1">
            <nav className="list-none">
              <div className="flex flex-col items-start md:block flex-1 space-y-2 py-2 md:space-x-4 lg:space-x-8">
                {navigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="font-medium text-gray-500 hover:text-white"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </nav>
          </div>
        </div>
        <div className="flex-1">
          <p className="py-1 text-sm text-white border-b border-gray-500">
            {description}
          </p>
          <div className="py-4 flex flex-row justify-between">
            <div className="text-gray-200">
              <div className="flex items-center">
                <PhoneIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                <a
                  className="text-current hover:text-white"
                  href={`tel:${contactPhoneNumber}`}
                >
                  {contactPhoneNumber}
                </a>
              </div>
              <div className="flex items-center">
                <MailIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                <a
                  className="text-current hover:text-white"
                  href={`mailto:${contactEmail}`}
                >
                  {contactEmail}
                </a>
              </div>
            </div>
            <div>
              <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                <a className="text-gray-200">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-200">
                  <svg
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-200">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
                <a className="ml-3 text-gray-200">
                  <svg
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="0"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="none"
                      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                    ></path>
                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div className="container mx-auto px-4 pb-4 md:px-0">
          <p className="text-gray-600 text-sm text-center sm:text-left">
            © {new Date().getFullYear()} Tekton Digital
          </p>
        </div>
      </div>
    </footer>
  )
}
