import React from "react"
import { Link } from "gatsby"
import { classNames } from "../../utils/classNames"

import TitleLogo from "../../assets/title.svg"

type Props = {
  className?: string
}

export const LogoLink: React.FC<Props> = ({ className }) => (
  <Link to="/" className={classNames("flex items-center", className)}>
    <TitleLogo className="h-full w-auto" alt="logo" />
  </Link>
)
